import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

import LayoutStore from "./LayoutStore";
import UserStore from "./UserStore";
import CartStore from "./CartStore";
import MallStore from "./MallStore";
import WalletStore from "./WalletStore";

export default new Vuex.Store({
    modules: {
        layout: LayoutStore,
        user: UserStore,
        cart: CartStore,
        mall: MallStore,
        wallet: WalletStore,
    }
});
