<template>
    <div class="background-filter position-absolute"
         ref="backgroundFilter">
        <progressive-background v-if="!isVideo"
                                v-bind:src="src"
                                v-bind:placeholder="placeholder">
        </progressive-background>

        <progressive-background class="video-poster position-absolute"
                                ref="poster"
                                v-bind:class="{ 'inactive': !isPosterActive }"
                                v-if="isVideo && poster"
                                v-bind:src="poster">
        </progressive-background>

        <!-- <youtube-cover class="d-none d-md-block"
                       v-if="windowWidth > 767 && isVideo && provider === 'youtube'"
                       v-bind:video-id="src"
                       v-on:play="hidePoster">
        </youtube-cover> -->

        <vimeo-cover class="d-none d-md-block"
                     v-if="windowWidth > 767 && isVideo && provider === 'vimeo'"
                     v-bind:video-id="src"
                     v-on:play="hidePoster">
        </vimeo-cover>
    </div>
</template>

<script>
    //import YoutubeCover from "./YoutubeCover";
    import VimeoCover from "./VimeoCover";
    export default {
        name: "BackgroundFilter",
        components: {
            VimeoCover,
        },
        props: {
            isVideo: {
                type: Boolean,
                default: false
            },

            provider: {
                type: String,
                validator: function (value) {
                    return ["vimeo"].includes(value);
                }
            },

            src: {
                type: String,
                required: true,
            },

            placeholder: {
                type: String,
            },

            poster: {
                type: String,
            },

            youtubePlayerVars: {
                type: Object,
            }
        },
        data () {
            return {
                windowWidth: window.innerWidth,

                isPosterActive: true,
            }
        },
        computed: {

        },
        methods: {
            hidePoster () {
                setTimeout(
                    () => {
                        this.isPosterActive = false;
                    },
                    1750
                );
            },
        }
    }
</script>

<style lang="scss" scoped>
    .background-filter {
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(225, 225, 225, 1);
        z-index: -1;
        overflow: hidden;
    }

    .video-poster {
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(225, 225, 225, 1);
        z-index: 1;
        overflow: hidden;
    }

    .youtube-wrapper {
        position: absolute;
        left: 0;
        width: 100%;
        pointer-events: none;
    }

    .vimeo-wrapper {

    }
</style>

<style lang="scss">
    .video-poster {
        transition: opacity 1250ms ease-in-out;

        &.inactive {
            opacity: 0;
        }
    }

    iframe {
        &.video-cover {
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            height: 100%;
            width: 100%;
        }
    }
</style>
