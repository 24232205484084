import { detect } from "detect-browser";

const plugin = {
    install (Vue) {
        let browser = detect();
        if (!browser) {
            browser = {
                name: "",
                version: "",
                os: ""
            }
        }
        Vue.prototype.$browser = browser;
    }
};

export default plugin;
