<template>
    <div class="product-category-filter">
        <div class="categories d-flex flex-row justify-content-around align-items-center mt-4 mb-3">
            <product-category-selector v-if="selectedCategoryElement"
                                       v-bind:target="selectedCategoryElement">
            </product-category-selector>

            <div class="category text-center"
                 ref="categoryElements"
                 v-for="category in internalCategories"
                 v-bind:key="category._id"
                 v-on:click="selectCategory(category, $event)">
                {{ $sanitize(category.name[$i18n.locale]) }}
            </div>
        </div>
        <div class="categories d-flex flex-row flex-wrap justify-content-around align-items-center mb-5">
            <product-category-selector v-if="selectedSubCategoryElement"
                                       v-bind:target="selectedSubCategoryElement">
            </product-category-selector>

            <div class="category text-center"
                 ref="subCategoryElements"
                 v-for="subCategory in subCategories"
                 v-bind:key="subCategory._id"
                 v-bind:id="subCategory._id"
                 v-on:click="selectSubCategory(subCategory, $event)">
                <span v-if="subCategory.name">
                    {{ $sanitize(subCategory.name[$i18n.locale]) }}
                </span>

                <span v-if="subCategory.title">
                    {{ $sanitize(subCategory.title[$i18n.locale]) }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    import ProductCategorySelector from "./ProductCategorySelector";

    export default {
        name: "ProductCategoryFilter",
        components: {
            ProductCategorySelector,
        },
        props: {
            "categories": {
                type: Array,
                required: true
            },

            "series": {
                type: Array,
                required: true
            }
        },
        data () {
            return {
                isMounted: false,

                selectedCategoryElement: null,
                selectedSubCategoryElement: null,

                internalCategories: [{
                    _id: "series",
                    name: {
                        "en": "Series",
                        "zh-hk": "系列",
                        "zh-cn": "系列"
                    },
                }, {
                    _id: "type",
                    name: {
                        "en": "Type",
                        "zh-hk": "類型",
                        "zh-cn": "类型"
                    },
                }, {
                    _id: "all",
                    name: {
                        "en": "All",
                        "zh-hk": "全部產品",
                        "zh-cn": "全部产品"
                    },
                }],
            }
        },
        computed: {
            categorySelected () {
                return this.$route.query.category;
            },

            subCategories () {
                switch (this.categorySelected) {
                    case "series": {
                        return this.series;
                    }

                    case "type": {
                        return this.categories;
                    }

                    case "all": {
                        return [];
                    }
                }
            },
        },
        methods: {
            selectCategory (category, evt) {
                this.selectedCategoryElement = evt.currentTarget;
                this.$router.push({
                    name: "products",
                    query: {
                        category: category._id,
                    }
                });
            },

            selectSubCategory (subCategory) {
                this.$router.push(
                    {
                        "name": "products",
                        "query": {
                            category: this.$route.query.category,
                            subcategory: subCategory._id
                        }
                    }
                );
            }
        },
        mounted () {
            if (this.$route.query.category) {
                this.selectedCategoryElement = this.$refs.categoryElements[this.internalCategories.findIndex((category) => category._id === this.$route.query.category)];
                if (this.$route.query.subcategory) {
                    this.$nextTick(() => {
                        const targetIndex = this.subCategories.findIndex((category) => category._id === this.$route.query.subcategory);
                        if (targetIndex > -1) {
                            this.selectedSubCategoryElement = this.$refs.subCategoryElements[targetIndex];
                        }
                    });
                }
            } else {
                this.selectedCategoryElement = this.$refs.categoryElements[this.$refs.categoryElements.length - 1];
            }
        },
        updated () {
            if (this.$route.query.category) {
                this.selectedCategoryElement = this.$refs.categoryElements[this.internalCategories.findIndex((category) => category._id === this.$route.query.category)];
                if (this.$route.query.subcategory) {
                    this.$nextTick(() => {
                        const targetIndex = this.subCategories.findIndex((category) => category._id === this.$route.query.subcategory);
                        if (targetIndex > -1) {
                            this.selectedSubCategoryElement = this.$refs.subCategoryElements[targetIndex];
                        }
                    });
                }
            } else {
                this.selectedCategoryElement = this.$refs.categoryElements[this.$refs.categoryElements.length - 1];
            }
        },
        watch: {
            "$route.query.category" (newVal) {
                if (newVal) {
                    this.selectedCategoryElement = this.$refs.categoryElements[
                        this.internalCategories
                            .findIndex(
                                (category) => category._id === newVal
                            )
                        ];
                } else {
                    this.selectedCategoryElement = this.$refs.categoryElements[this.$refs.categoryElements.length - 1];
                }
            },

            "$route.query.subcategory" (newVal) {
                if (!this.$route.query.category) {
                    return;
                }

                if (newVal) {
                    this.$nextTick(() => {
                        const targetIndex = this.subCategories
                            .findIndex(
                                (category) => category._id === newVal
                            );
                        if (targetIndex > -1) {
                            this.selectedSubCategoryElement = this.$refs.subCategoryElements[targetIndex];
                        }
                    });
                } else {
                    this.selectedSubCategoryElement = null;
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
    .product-category-filter {
        & > .categories {
            position: relative;

            font-size: 18px;
            font-weight: normal;

            min-height: 65.5px;
            padding-bottom: 17.5px;

            &:first-child {
                border-bottom: solid 1px rgba(220, 220, 220, 1);
            }

            & > .category {
                flex: 1;

                white-space: nowrap;
                user-select: none;
                cursor: pointer;

                padding: 10px;

                &:hover {
                    font-weight: bold;
                }
            }
        }
    }
</style>
