<template>
    <article class="product position-relative h-100">
        <router-link class="d-block h-100 product-link"
                     v-bind:to="to">
            <div class="img-wrapper d-flex justify-content-center align-items-end position-relative">
                <img class="image d-block mb-2 w-auto mw-100 mh-100"
                     v-bind:alt="item.title[$i18n.locale]"
                     v-bind:src="`${ $config.apiUrl }/${ $config.apiVersion }/shop/product/image/${ item.images[0] }`">
            </div>
            <div>
                <h4 class="title text-center d-flex justify-content-center align-items-center"
                    v-html="item.title[$i18n.locale]"></h4>
                <p class="subtitle text-center mb-1"
                   v-html="item.subtitle[$i18n.locale]"></p>
            </div>
            <div class="d-flex justify-content-center align-items-center">
                <h3 class="text-center">
                    <small>HK$</small>{{ item.prices[0].hkd | currency("", 0) }}
                </h3>
            </div>
        </router-link>
        <div class="btn-wrapper position-absolute">
            <button-add-to-cart v-bind:item="item"></button-add-to-cart>
        </div>
    </article>
</template>

<script>
    import ButtonAddToCart from "./ButtonAddToCart";
    export default {
        name: "Product",
        components: {
            ButtonAddToCart,
        },
        props: {
            "item": {
                type: Object,
                required: true
            },

            "to": {
                type: Object,
                required: true
            }
        },
        data () {
            return {
                isLoading: false,
            }
        },
        computed: {

        }
    }
</script>

<style lang="scss" scoped>
    .product {
        padding-bottom: 52px;
        cursor: pointer;
        transition:
            background-color 250ms ease,
            box-shadow 250ms ease,
            border-radius 250ms ease;

        &:hover {
            box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
            background-color: rgba(255, 255, 255, 0.75);
            backdrop-filter: blur(2px);
        }

        & > .product-link {
            text-decoration: none;
            color: rgba(113, 113, 113, 1);

            .img-wrapper {
                height: 300px;
                max-width: 100%;
            }

            .title {
                min-height: 56px;
            }

            .subtitle {

            }
        }

        & > .btn-wrapper {
            left: 0;
            bottom: 0.5rem;
            right: 0;

            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
</style>
