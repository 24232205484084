import locale2 from "locale2";
import VueI18n from "vue-i18n";

import enLangMessage from "../langs/en";
import zhHkLangMessage from "../langs/zh-hk";
import zhCnLangMessage from "../langs/zh-cn";

export default function (Vue) {
    console.log(locale2);
    Vue.use(VueI18n);
    const messages = Object.assign(
        {},
        enLangMessage,
        zhHkLangMessage,
        zhCnLangMessage
    );

    return new VueI18n(
        {
            "locale": "zh-hk",
            "fallbackLocale": "zh-hk",
            messages,
        }
    );
}
