<template>
    <section class="view product-series">
        <div class="banner position-relative d-flex align-items-center py-3 mb-4">
            <background-filter v-if="!series.videoCover"
                               v-bind:src="`${ $config.apiUrl }/${ $config.apiVersion }/shop/series/image/${ series.cover }`">
            </background-filter>

            <background-filter v-else
                               v-bind:is-video="series.videoCover"
                               v-bind:provider="series.videoCover.provider"
                               v-bind:src="series.videoCover.src"
                               v-bind:poster="`${ $config.apiUrl }/${ $config.apiVersion }/shop/series/image/${ series.cover }`">
            </background-filter>

            <div class="container">
                <div class="row">
                    <div class="col-md-5 pr-md-4 pb-4 pb-md-0"
                         data-aos="fade-up"
                         data-aos-duration="800"
                         data-aos-delay="300">
                        <div class="product-image position-relative text-center">
                            <img class="img-responsive"
                                 v-bind:src="`${ $config.apiUrl }/${ $config.apiVersion }/shop/series/image/${ series.image }`">
                        </div>
                    </div>
                    <div class="col-md-7 d-flex flex-column justify-content-center"
                         data-aos="fade-in"
                         data-aos-duration="1000"
                         data-aos-delay="400">
                        <h1 class="text-center font-weight-bolder text-md-left display-4"
                            v-bind:style="{ 'color': series.headlineColor ? `rgba(${ series.headlineColor.r }, ${ series.headlineColor.g }, ${ series.headlineColor.b }, ${ series.headlineColor.a }) !important` : '' }"
                            v-html="series.headline[$i18n.locale]">
                        </h1>

                        <h3 class="text-center text-md-left"
                            v-bind:style="{ 'color': series.headlineColor ? `rgba(${ series.headlineColor.r }, ${ series.headlineColor.g }, ${ series.headlineColor.b }, ${ series.headlineColor.a }) !important` : '' }"
                            v-html="series.subheadline[$i18n.locale]">
                        </h3>
                    </div>
                </div>
            </div>
        </div>

        <div class="description mb-4">
            <div class="container py-4"
                 v-bind:style="{ 'background-image': `url(${ $config.apiUrl }/${ $config.apiVersion }/shop/series/image/${ series.background.description })` }">
                <h1 class="text-center"
                    data-aos="fade-up"
                    data-aos-duration="800"
                    data-aos-delay="600"
                    v-html="series.title[$i18n.locale]">
                </h1>

                <h4 class="text-center mb-3"
                    data-aos="fade-up"
                    data-aos-duration="800"
                    data-aos-delay="600"
                    v-html="series.subtitle[$i18n.locale]">
                </h4>

                <p class="text-justify mb-0"
                   data-aos="fade-in"
                   data-aos-duration="1000"
                   data-aos-delay="1000"
                   v-html="series.description[$i18n.locale]">
                </p>
            </div>
        </div>
        <div class="products py-5"
             v-bind:style="{ 'background-image': `url(${ $config.apiUrl }/${ $config.apiVersion }/shop/series/image/${ series.background.product })` }">
            <div class="container">
                <div class="row d-none d-lg-flex">
                    <div class="col-lg-4 mb-5"
                         data-aos="fade-up"
                         data-aos-duration="800"
                         data-aos-delay="150"
                         v-for="product in products"
                         v-bind:key="product._id">
                        <product v-bind:item="product"
                                 v-bind:lang="$i18n.locale"
                                 v-bind:to="{ name: 'product-details', params: { id: product._id } }">
                        </product>
                    </div>
                </div>

                <div class="row d-lg-none">
                    <div class="col-md-6 mb-5"
                         data-aos="fade-up"
                         data-aos-duration="800"
                         data-aos-delay="150"
                         v-for="product in products"
                         v-bind:key="product._id">
                        <product v-bind:item="product"
                                 v-bind:lang="$i18n.locale"
                                 v-bind:to="{ name: 'product-details', params: { id: product._id } }">
                        </product>
                    </div>
                </div>

                <p class="text-center term-links">
                    <router-link to="/payment-methods">
                        {{ $t("message.products.payment-methods-terms") }}
                    </router-link> |
                    <router-link to="/online-shopping-terms">
                        {{ $t("message.products.online-shopping-terms") }}
                    </router-link> |
                    <router-link to="/terms">
                        {{ $t("message.products.terms") }}
                    </router-link> |
                    <router-link to="/delivery-methods">
                        {{ $t("message.products.delivery-methods-terms") }}
                    </router-link>
                </p>
            </div>
        </div>
    </section>
</template>

<script>
import BackgroundFilter from "../components/BackgroundFilter";
import Product from "../components/Products/Product";
export default {
    name: "ProductSeries",
    components: {
        Product,
        BackgroundFilter
    },
    metaInfo () {
        return {
            title: this.$sanitize(`${ this.series.headline[this.$i18n.locale] }`),
            meta: [{
                "name": "description",
                "content": this.$sanitize(this.series.description[this.$i18n.locale]),
                "vmid": "description",
            }, {
                "property": "og:title",
                "content": this.$sanitize(`${ this.series.headline[this.$i18n.locale] }`),
                "template": "%s | marier Skincare 曼肌兒",
                "vmid": "og:title"
            }, {
                "property": "og:description",
                "content": this.$sanitize(this.series.description[this.$i18n.locale]),
                "vmid": "og:description"
            }, {
                "property": "og:type",
                "content": "product.group",
                "vmid": "og:type"
            }, {
                "property": "og:image",
                "content": `${ this.$config.apiUrl }/${ this.$config.apiVersion }/shop/series/image/${ this.series.image }`,
                "vmid": "og:image"
            }]
        }
    },
    props: {
        "id": {
            type: String,
        },
    },
    data () {
        return {
            columnCount: 3,
        }
    },
    computed: {
        series () {
            const target = this.$store.state.mall.shop.series
                .find(
                    ({ _id }) => _id === this.id
                );
            if (target) {
                return target;
            }

            return {
                title: {
                    "en": "",
                    "zh-hk": "",
                    "zh-cn": ""
                },
                subtitle: {
                    "en": "",
                    "zh-hk": "",
                    "zh-cn": ""
                },
                headline: {
                    "en": "",
                    "zh-hk": "",
                    "zh-cn": ""
                },
                subheadline: {
                    "en": "",
                    "zh-hk": "",
                    "zh-cn": ""
                },
                description: {
                    "en": "",
                    "zh-hk": "",
                    "zh-cn": ""
                },
                background: {
                    description: "",
                    product: "",
                },
                videoCover: null,
            };
        },

        products () {
            return this.$store.state.mall.shop
                .products
                .filter(
                    ({ series }) => series === this.$route.params.id
                );
        }
    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>
.product-series {
    color: rgba(113, 113, 113, 1);

    & > .banner {
        min-height: 40vh;

        .img-responsive {
            height: auto;
            max-height: 500px;
            max-width: 100%;
        }

        .product-image {

        }
    }

    & > .description {
        & > .container {
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
        }
    }

    & > .products {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom center;
    }

    .term-links {
        a {
            color: rgba(113, 113, 113, 1);

            &:hover {
                color: rgba(82, 82, 82, 1);
            }
        }
    }
}
</style>
