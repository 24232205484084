<template>
    <div class="btn-add-to-cart position-relative"
         v-bind:class="{ 'active': noticeActive }">
        <button class="position-relative btn btn-outline-dark rounded-0 d-block mx-auto"
                v-bind:class="{ 'loading': isLoading }"
                v-on:click="addToCart">
            <span class="placeholder">{{ $t("message.products.added-to-cart") }}</span>
            <div class="title position-absolute d-flex justify-content-center align-items-center">{{ $t("message.products.add-to-cart") }}</div>
        </button>
        <div class="msg-added-to-cart position-absolute position-relative btn btn-outline-dark rounded-0 d-block mx-auto">
            {{ $t("message.products.added-to-cart") }}
        </div>
    </div>
</template>

<script>
    export default {
        name: "ButtonAddToCart",
        props: {
            item: {
                type: Object,
                required: true,
            },
        },
        data () {
            return {
                isLoading: false,
                noticeActive: false,
            };
        },
        methods: {
            addToCart () {
                this.$store.commit(
                    "cart/setItemQuantity",
                    {
                        "type": "products",
                        "item": this.item,
                        "quantity": 1
                    }
                );
                this.noticeActive = true;
                window.setTimeout(
                    () => {
                        this.noticeActive = false;
                    },
                    3000
                ); 
            }
        }
    }
</script>

<style lang="scss" scoped>
    .btn-add-to-cart {
        transform: rotateX(0deg);
        transform-style: preserve-3d;
        transition: transform 450ms ease;

        &.active {
            transform: rotateX(-90deg);
        }

        button {
            background-color: rgba(255, 255, 255, 1);

            transform: translateZ(18px);
            transform-style: preserve-3d;

            &:hover,
            &:focus {
                background-color: rgba(113, 113, 113, 1);
                color: rgba(255, 255, 255, 1);
            }

            .placeholder {
                color: transparent;
            }

            .title {
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
            }
        }

        .msg-added-to-cart {
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;

            background-color: rgba(113, 113, 113, 1);
            color: rgba(255, 255, 255, 1);
            white-space: nowrap;

            transform: rotateX(90deg) translateZ(18px);
            //transform-origin: top;

            transition: transform 350ms ease;

            z-index: 1;
        }
    }
</style>
