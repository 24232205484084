<template>
    <section class="view treatments">
        <view-title reveal-animation="fade-down"
                    v-bind:title="$t('message.treatments.title')">
        </view-title>
        <article class="row treatment position-relative no-gutters"
                 v-for="series in treatmentSeries"
                 v-bind:key="series._id">
            <div class="bg-wrapper position-absolute d-none d-md-block">
                <div class="container-fluid h-100 px-0">
                    <div class="row h-100 no-gutters">
                        <div class="col-md-7 d-flex flex-column py-4 h-100"></div>
                        <div class="col-md-5 cover h-100"
                             v-bind:style="{ 'background-image': `url(${ $config.apiUrl }/${ $config.apiVersion }/salon/series/image/${ series.cover })` }"></div>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row m-0">
                    <div class="col-md-7 d-flex flex-column py-4 order-1 order-md-0">
                        <h1 class="mb-2"
                            v-bind:id="series._id"
                            v-html="series.title[$i18n.locale]">
                        </h1>
                        <div class="row flex-1">
                            <div class="col-md-6 d-flex flex-column align-items-stretch"
                                 v-for="n in Math.ceil(treatments.filter(treatment => treatment.series === series._id).length / rowCount)"
                                 v-bind:key="n">
                                <router-link class="d-block item"
                                             v-for="{ _id, title } in treatments.filter(treatment => treatment.series === series._id).slice((n - 1) * rowCount, n * rowCount)"
                                             v-bind:key="_id"
                                             v-bind:to="{ name: 'treatment-details', params: { id: _id } }"
                                             v-html="title[$i18n.locale]">
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5 d-none d-md-block"></div>
                    <div class="col-md-5 d-block d-md-none cover order-0 order-md-1"
                         v-bind:style="{ 'background-image': `url(${ $config.apiUrl }/${ $config.apiVersion }/salon/series/image/${ series.cover })` }"></div>
                </div>
            </div>
        </article>
    </section>
</template>

<script>
    import ViewTitle from "../components/ViewTitle";
    export default {
        name: "Treatments",
        components: {
            ViewTitle
        },
        metaInfo: {
            title: "療程一覽",
        },
        data () {
            return {
                rowCount: 5,
            }
        },
        computed: {
            treatmentSeries () {
                return this.$store.state.mall.salon.series
            },

            treatments () {
                return this.$store.state.mall.salon.treatments;
            }
        },
        methods: {
            filterTreatments (treatments, seriesId) {
                return treatments.filter(treatment => treatment.series = seriesId);
            }
        }
    }
</script>

<style lang="scss" scoped>
    .treatments {
        color: rgba(113, 113, 113, 1);

        .treatment {
            min-height: 30vh;

            & > .bg-wrapper {
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
            }

            .item {
                padding: 10px 0;
                color: rgba(113, 113, 113, 1);

                &:hover {
                    text-decoration: underline;
                }
            }

            .cover {
                box-shadow: 25px 0 50px 0 rgba(255, 255, 255, 1) inset;
                background-color: rgba(225, 225, 225, 1);
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
                min-height: 250px;
            }
        }
    }
</style>
