<template>
    <section class="view products">
        <view-title reveal-animation="fade-down"
                    v-bind:title="$t('message.products.title')">
        </view-title>

        <div class="container">
            <product-category-filter v-bind:categories="categories"
                                     v-bind:series="series">
            </product-category-filter>

            <div class="row d-none d-lg-flex">
                <div class="col-lg-4 mb-5"
                     data-aos="fade-up"
                     data-aos-duration="800"
                     data-aos-delay="300"
                     v-for="product in filteredProducts"
                     v-bind:key="product._id">
                    <product v-bind:item="product"
                             v-bind:lang="$i18n.locale"
                             v-bind:to="{ name: 'product-details', params: { id: product._id } }">
                    </product>
                </div>
            </div>

            <div class="row d-lg-none">
                <div class="col-md-6 mb-5"
                     data-aos="fade-up"
                     data-aos-duration="800"
                     data-aos-delay="150"
                     v-for="product in filteredProducts"
                     v-bind:key="product._id">
                    <product v-bind:item="product"
                             v-bind:lang="$i18n.locale"
                             v-bind:to="{ name: 'product-details', params: { id: product._id } }">
                    </product>
                </div>
            </div>

            <p class="text-center term-links">
                <router-link to="/payment-methods">
                    {{ $t("message.products.payment-methods-terms") }}
                </router-link> |
                <router-link to="/online-shopping-terms">
                    {{ $t("message.products.online-shopping-terms") }}
                </router-link> |
                <router-link to="/terms">
                    {{ $t("message.products.terms") }}
                </router-link> |
                <router-link to="/delivery-methods">
                    {{ $t("message.products.delivery-methods-terms") }}
                </router-link>
            </p>
        </div>
    </section>
</template>

<script>
    import ViewTitle from "../components/ViewTitle";
    import ProductCategoryFilter from "../components/Products/ProductCategoryFilter";
    import Product from "../components/Products/Product";

    export default {
        name: "Products",
        components: {
            Product,
            ViewTitle,
            ProductCategoryFilter
        },
        metaInfo: {
            title: "產品一覽",
        },
        data () {
            return {

            };
        },
        computed: {
            categories () {
                return this.$store.state.mall.shop.categories;
            },

            series () {
                return this.$store.state.mall.shop.series;
            },

            products () {
                return this.$store.state.mall.shop.products;
            },

            filteredProducts () {
                if (this.$route.query.subcategory) {
                    switch (this.$route.query.category) {
                        case "series": {
                            return this.products.filter(product => product.series === this.$route.query.subcategory)
                        }

                        case "type": {
                            return this.products.filter(product => product.category === this.$route.query.subcategory);
                        }

                        case "all": {
                            return this.products;
                        }
                    }
                }
                return this.products;
            }
        },
        methods: {

        }
    }
</script>

<style lang="scss" scoped>
    .products {
        .term-links {
            a {
                color: rgba(113, 113, 113, 1);

                &:hover {
                    color: rgba(82, 82, 82, 1);
                }
            }
        }
    }
</style>
