import localforage from "localforage";

export const CartStore = {
    namespaced: true,
    state: {
        "cart": {
            products: [],
            treatments: [],
        },
        "coupon": {
            products: [],
            treatments: [],
        },
    },
    mutations: {
        setCart (
            state,
        ) {
            state.cart = {
                "products": localStorage.getItem("marier.cart.products") ?
                    JSON.parse(localStorage.getItem("marier.cart.products")) :
                    [],
                "treatments": localStorage.getItem("marier.cart.treatments") ?
                    JSON.parse(localStorage.getItem("marier.cart.treatments")) :
                    [],
            };
        },

        setItemQuantity (
            state,
            { type, item, quantity }
        ) {
            const target = state.cart[type].find(
                ({ _id }) => _id === item._id
            );
            if (target) {
                target.quantity += quantity;
            } else {
                state.cart[type].push(
                    Object.assign(
                        {},
                        item,
                        {
                            "quantity": parseInt(quantity) ? parseInt(quantity) : 1
                        }
                    )
                );
            }
            localStorage.setItem(
                `marier.cart.${ type }`,
                JSON.stringify(state.cart[type])
            );
        },

        deleteItem (
            state,
            { type, itemId }
        ) {
            state.cart[type].splice(
                state.cart[type].findIndex(
                    ({ _id }) => _id === itemId
                ),
                1
            );
            localStorage.setItem(
                `marier.cart.${ type }`,
                JSON.stringify(state.cart[type])
            );
        },

        clearCart (state, type) {
            localStorage.setItem(
                `marier.cart.${ type }`,
                JSON.stringify([])
            );
            state.cart[type] = [];
        },

        setCoupon (
            state, {
                type,
                coupons
            }
        ) {
            state.coupon[type] = coupons;
        },
    },
    actions: {

    }
};

export default CartStore;
