//import * as sanitizeHtml from "sanitize-html";

function strip (htmlString) {
    const domParser = new DOMParser();
    const doc = domParser.parseFromString(
        htmlString,
        "text/html"
    );
    return doc.body.textContent || "";
}

const plugin = {
    install (Vue, options = { allowedTags: [] }) {
        Vue.prototype.$sanitize = strip;
    }
};

export default plugin;
