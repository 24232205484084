const index = {
    install: function (Vue, options) {
        Vue.directive("sticky-event", {
            bind (el, binding, vnode, oldVnode) {

            },

            inserted (el, binding, vnode, oldVnode) {
                const observer = new IntersectionObserver(
                    (records, observer) => {
                        records.forEach(
                            record => {
                                const targetInfo = record.boundingClientRect;
                                const rootBoundsInfo = record.rootBounds;

                                // Started sticking.
                                if (
                                    targetInfo.bottom < rootBoundsInfo.top
                                ) {
                                    const e = new CustomEvent("stuck", {
                                        detail: {
                                            stuck: true,
                                            stickyTarget: el,
                                        }
                                    });
                                    el.dispatchEvent(e);
                                }

                                // Stopped sticking.
                                if (
                                    targetInfo.bottom >= rootBoundsInfo.top &&
                                    targetInfo.bottom < rootBoundsInfo.bottom
                                ) {
                                    const e = new CustomEvent("unstuck", {
                                        detail: {
                                            stuck: false,
                                            stickyTarget: el,
                                        }
                                    });
                                    el.dispatchEvent(e);
                                }
                            }
                        );
                    }, {
                        threshold: [0],
                        root: null
                    }
                );

                let top = -1;
                if (binding.expression) {
                    top = parseFloat(binding.expression) * -1 - 1;
                }

                const sentinelStyle = document.createElement("style");
                sentinelStyle.innerHTML = `
                .sticky_sentinel {
                    position: absolute;
                    top: ${ top }px;
                    left: 0;
                    right: 0; 
                    visibility: hidden;
                    height: 0; 
                }`;
                const sentinel = document.createElement("div");
                sentinel.classList.add("sticky_sentinel");
                el.appendChild(sentinelStyle);
                el.appendChild(sentinel);
                observer.observe(sentinel)
            },

            update (el, binding, vnode, oldVnode) {

            },

            componentUpdated (el, binding, vnode, oldVnode) {

            },

            unbind (el, binding, vnode, oldVnode) {

            }
        })
    }
};

export default index;

